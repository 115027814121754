<template>
  <div class="profile-settings d-flex flex-row">
    <profile-menu class="menu align-self-start" separated /> 
    <div class="content flex-grow-1 ">
      <router-view />
    </div>
  </div>
</template>

<script>
import ProfileMenu from '../ProfileMenu.vue'

export default {
  components: {
    ProfileMenu
  }
}
</script>

<style lang="scss" scoped>
.profile-settings {
  padding: 4rem; 
}

.menu {
  padding: 1rem;
  width: 220px;

  border-radius: 0.5rem;
}

.content {
  margin-left: 2rem;
}
</style>